@import '../../styles/variables';

.card-wrapper {
  min-width:90%;
}

.custom-skills--cell{
  flex-wrap: wrap;
  overflow: scroll !important;
  gap: 5px;
  padding-top:5px !important;
  padding-bottom:5px !important;
  align-content: top;
}
