@import '../../styles/variables';

.skill-definition-wrapper {
 display: flex;
}

.edit-skill-link {
  margin: auto 20px;
  text-decoration: none;
}

.skill-attribution {
  font-size: 14px;
  color: gray;
}

.tendo-primary-link {
  background-color: $primary;
  text-transform: capitalize;
  text-decoration: none;
  color: #FFF;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid $primary;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin: 20px 0;
}

.action-links {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 20px 0 20px auto;
}

.validations {
  display: flex;
  align-items: center;
}

.verified-icon {
  color: $primary;
  margin-right: 5px;
  cursor: pointer;
  &.disabled {
    color: lightgray;
    cursor: inherit;
  }
}
