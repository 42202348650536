@import '../../styles/variables';

.profile-title {
  margin-left: 10px;
  text-transform: uppercase;
}

.skill-layout {
  display: flex;
  flex-wrap: wrap;
}

.skill-link {
  background-color: $tendo-light;
  text-decoration: none;
  color: $primary;
  margin: 5px 3px;
  padding: 8px 16px;
  border-radius: 20px;
}
