.dropdown-label {
  font-weight: 600;
  text-transform: capitalize;
}

.glossary-filter-wrapper {
  .MuiAutocomplete-root {
    margin: 20px 0;
  }
}

.filter-dropdown-wrapper {
  display: flex;
  flex-direction: column;
}
