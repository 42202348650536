@import '../../styles/variables';

.dropdown-title {
  text-transform: capitalize;
}
.dropdown-wrapper {
  display: flex;
}

.icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  display: inline;
  &:hover {
    background-color: $tendo-light;
  }
}

.dropdown-placeholder {
  color: $disabled;
}

.dropdown-field {
  margin: 0;
}
