@import '../../styles/variables';

.navigation-wrapper {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
}

.nav-account-icons {
  display: flex;
}

.nav-user-icon {
  color: $primary;
  margin-right: 10px;
}

.navigation-link {
  height: 33px;
}
