.form-title {
  margin: 0 auto;
}

.side-layout {
  display: flex;
  flex-direction: column;
}

.form-container {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
    .MuiFormControl-root {
      margin: 20px 0;
    }
}

.submit-button {
  align-self: center;
  display: flex;
  gap: 16px;
}

.error-message {
  color: red;
  margin: 0 auto;
}

.completed-message {
  margin: 0 auto;
}


@media only screen and (min-width: 768px) {
  .side-layout {
    flex-direction: row;
  }

  .form-title {
    margin: 20px 0 0 20px;
  }

  .form-container {
    flex-grow: 1;
    margin: 50px 20px 0;
  }

  .submit-button {
    align-self: flex-start;
  }
}
