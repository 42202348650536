@import '../../styles/variables';

.card-inner-wrapper {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  width: 270px;
}

.glossary-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.tendo-primary-link {
 &.add-skill-link {
  margin: auto 0 auto 20px;
  display: flex;
  align-items: center;
  font-size: 0px;
 }
}


.definition {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.filter-button {
 margin: 0 10px 10px 0;
}
@media only screen and (min-width: 768px) {
  .card-inner-wrapper {
    width: auto;
  }

.tendo-primary-link {
  &.add-skill-link {
    font-size: 12px;
  }
}

  .definition {
    width: 75%;
  }
}
