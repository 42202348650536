@import 'variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

.page-wrapper {
  padding: 21px 20px;
  background-color: $tendo-light;
  min-height: 100vh;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tendo-primary-button {
  background-color: $primary;
  text-transform: capitalize;
  color: #FFF;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid $primary;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  &.disabled {
    background-color: $disabled;
    border: 1px solid $disabled;
    cursor: initial;
  }
}

.tendo-primary-input {
  background-color: $primary;
  text-transform: capitalize;
  color: #FFF;
  padding: 0px 32px 2px;
  border-radius: 6px;
  border: 1px solid $primary;
  height: 44px;
  font-weight: 600;
  font-size: 18px;
  margin: 20px 0;
  &.disabled {
    background-color: $disabled;
    border: 1px solid $disabled;
  }
  &.small {
    max-width: 130px;
  }
}

.center {
  align-self: center;
}

.card-wrapper {
  min-width: 270px;
  // max-width: 300px;
  width: 100%;
  max-width: 900px;
  margin: 20px 0;
}

.card-background {
  background-color: #FFFF;
  box-shadow: 0px 4px 16px rgba(0, 71, 171, 0.12);
  border-radius: 6px;
  padding: 15px;
  flex: 1;
}